<template>
  <div id="cuentas">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <card type="cuentas default">
            <div class="calibracion">
              <div class="row">
                <div class="col-12">
                  <h1>Selecciona las cuentas que deseas monitorear</h1>
                  <h3>
                    Escoge una o mas de tus cuentas para avanzar y monitorear
                    diferentes menciones.
                  </h3>
                </div>
                <div class="col-12 col-md-4">
                  <div class="card-redsocial">
                    <div class="banner facebook1">
                      <font-awesome-icon
                        :icon="['fab', 'facebook-f']"
                        v-if="!facebook"
                        size="3x"
                      />
                      <div
                        v-else
                        class="swal2-icon swal2-success swal2-animate-success-icon"
                      >
                        <div
                          class="swal2-success-circular-line-left"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                        <span class="swal2-success-line-tip"></span>
                        <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div
                          class="swal2-success-circular-line-right"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                      </div>
                    </div>
                    <div class="menu">
                      <div class="opener">
                        <span></span><span></span><span></span>
                      </div>
                    </div>
                    <h3 class="name">Facebook</h3>
                    <div class="title">Red Social</div>
                    <div class="actions">
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onSubmit1)">
                          <facebook-login
                            appId="785463118919880"
                            @login="onLogin"
                            @logout="onLogout"
                            @sdk-loaded="sdkLoaded"
                          >
                          </facebook-login>

                                                    <br>
                                                    <br> 
                                                </form>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4"> 
                                    <div class="card-redsocial">
                                        <div class="banner instagram1">
                                            <font-awesome-icon :icon="['fab', 'instagram']" v-if="!instagram" size="3x"/>
                                            <div v-else class="swal2-icon swal2-success swal2-animate-success-icon">
                                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                                                <span class="swal2-success-line-tip"></span>
                                                <span class="swal2-success-line-long"></span>
                                                <div class="swal2-success-ring"></div>
                                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                                            </div>
                                        </div>
                                        <div class="menu">
                                            <div class="opener"><span></span><span></span><span></span></div>
                                        </div>
                                        <h3 class="name">Instagram</h3>
                                        <div class="title">Red Social</div>
                                        <div class="actions">
                                            <ValidationObserver v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(onSubmit4)">
                                                    <button type="submit" id="instagrgam" class="btn btn-bextsocial" >Validar</button>
                                                </form>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4"> 
                                    <div class="card-redsocial">
                                        <div class="banner twitter1">
                                            <font-awesome-icon :icon="['fab', 'twitter']" v-if="!twitter" size="3x"/>
                                            <div v-else class="swal2-icon swal2-success swal2-animate-success-icon">
                                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                                                <span class="swal2-success-line-tip"></span>
                                                <span class="swal2-success-line-long"></span>
                                                <div class="swal2-success-ring"></div>
                                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                                            </div>
                                        </div>
                                        <div class="menu">
                                            <div class="opener"><span></span><span></span><span></span></div>
                                        </div>
                                        <h3 class="name">Twitter</h3>
                                        <div class="title">Red Social</div>
                                        <div class="actions">
                                            <ValidationObserver v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(onSubmit12)">
                                                    <button type="submit" class="btn btn-bextsocial" >Validar</button>
                                                </form>
                                            </ValidationObserver>
                                        </div>                             
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="btn btn-bextsocial next" @click="nextSocial()">Siguiente</button>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/card/Card.component";
import TextInput from "@/components/inputForms/InputForms.component";
import { extend } from "vee-validate";
import { mapMutations, mapState } from "vuex";
import facebookLogin from "facebook-login-vuejs";

const firebase = require("firebase/app");

require("firebase/auth");
require("firebase/firestore");

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYr73fIyuxaSGihSm80Okq-AIC4PKBsJM",
  authDomain: "bextsocial-fc6c9.firebaseapp.com",
  databaseURL: "https://bextsocial-fc6c9.firebaseio.com",
  projectId: "bextsocial-fc6c9",
  storageBucket: "bextsocial-fc6c9.appspot.com",
  messagingSenderId: "1092755478427",
  appId: "1:1092755478427:web:cd9adcaeb960a138a5b33e",
  measurementId: "G-6PTBFWLS2V",
};

firebase.initializeApp(firebaseConfig);

extend("positive", (value) => {
  console.log(value);
  return value >= 0;
});
export default {
  name: "Sincronizacion",
  components: {
    Card,
    TextInput,
    facebookLogin,
  },
  data() {
    return {
      email: "",
      password: "",
      email1: "",
      password1: "",
      email2: "",
      password2: "",
      nextSincronitation: "",
      facebook: false,
      instagram: false,
      twitter: false,
      personalID: "",
      name: "",
      FB: undefined,
      tokenFacebook: "",
      tokenPage: "",
      id_comentario_facebook: "",
      id_post_facebook: "",
      comentario_facebook: "",
      usuario_comentario_facebook: "",
      seguidores_usuario_comentario_facebook: "",
    };
  },
  computed: {
    ...mapState(["syncounts"]),
  },
  beforeRouteLeave(to, from, next) {
    if (!this.syncounts) {
      this.$hijonew({
        group: "foo1",
        type: "danger",
        title: "Error",
        text: "Primero debe sincronizar una cuenta",
        duration: 1000,
        speed: 1000,
      });
      next();
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations(["Carga", "changeSyncCounts"]),
    onSubmit1() {
      //entonces obteniendo el id sabemos cuales son los datos que esta enviando
      this.nextSincronitation = "facebook";
      this.Carga();
      setTimeout(
        function(scope) {
          scope.Carga();
          scope.facebook = true;
        },
        2000,
        this
      );
      //setTimeout(() => this.Carga(),this.facebook = true, 8000);
    },
    onSubmit2() {
      //entonces obteniendo el id sabemos cuales son los datos que esta enviando
      this.nextSincronitation = "instagram";
      this.Carga();
      setTimeout(
        function(scope) {
          scope.Carga();
          let provider = new firebase.auth.FacebookAuthProvider();
          firebase.auth().signInWithPopup(provider).then(function(result) {
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          var token = result.credential.accessToken;
          console.log(token)
          // The signed-in user info.
          var user = result.user;
          console.log(user)
          // ...
        }).catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
        });
          scope.instagram = true;
        },
        2000,
        this
      );
    },
    onSubmit3(){

      
    },
    onSubmit12() {
      //entonces obteniendo el id sabemos cuales son los datos que esta enviando
      this.Carga();
      this.nextSincronitation = true;
      setTimeout(
        function(scope) {
          scope.Carga();
          // Using a popup.
          const provider = new firebase.auth.TwitterAuthProvider();
          firebase
            .auth()
            .signInWithPopup(provider)
            .then(function(result) {
              // For accessing the Twitter API.
              const token = result.credential.accessToken;
              //console.log(token);
              const secret = result.credential.secret;
              //console.log(secret);
              // The signed-in user info.
              const user = result.additionalUserInfo["username"];
              //console.log(result, user);

              const img_profile = result.additionalUserInfo.profile["profile_image_url_https"];
              //console.log(img_profile);

              //scope.posDatosTwitter(user)

              const vuex = JSON.parse(localStorage.getItem("vuex"));
              let userid = vuex.user.users[0].Id_Usuario

              const axios = require('axios');
              const response = axios.post('/bextsocial/usertwitter',{
                id: userid,
                user: user.displayName,
                token: token,
                secret: secret
              }).then(function (response) {
                    console.log(response);
                    let obj = Object(response.data)
                    console.log(Object.keys(response.data).length);
                    scope.posDatosTwitter(obj)
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              console.log(response);
              if (response.status == 200) {
                this.$notify({
                  group: "foo",
                  type: "success",
                  title: "",
                  text: "token Enviado",
                  duration: 5000,
                  speed: 1000,
                });
              };

              // const tweet = axios.post('/bextsocial/usertwitter/tweetsid',{
              //   tweetid: '1120788374036652032'

              // }).then(function (tweet) {
              //       console.log(tweet);
              //     })
              //     .catch(function (error) {
              //       console.log(error);
              //     });

            });
          scope.twitter = true;
          localStorage.setItem("loginWith", "twitter");
        },
        2000,
        this
      );
    },
    posDatosTwitter(obj){

      let fecha1 = new Date();
      let fecha2 = new Date();
      const vuex = JSON.parse(localStorage.getItem("vuex"));
      let userid = vuex.user.users[0].Id_Usuario;

      let limit = Object.keys(obj).length;

      
      for (let i = 0; i < limit; i++) {
         const response = this.axios
        .post("/bextsocial/twitter", {
          id: userid,
          screen_name: obj[i].user.name,
          created_at: fecha1,
          description: obj[i].text,
          location: obj[i].geo,
          url: obj[i].source,
          name: obj[i].user.name,
          status: fecha2,
          id_str: obj[i].id_str,
          lang: obj[i].lang,
          retweet_count: obj[i].retweet_count,
          screen_name2: obj[i].user.name,
          location2: obj[i].user.location,
          profile_image_url: obj[i].user.profile_image_url,
          statuses_count: obj[i].user.statuses_count,
          description2: obj[i].user.description,
          name2: obj[i].user.screen_name,
          friends_count: obj[i].user.friends_count,
        },)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
      }
    

    },
    async nextSocial(){
          if (localStorage.loginWith=="facebook") {
            try {
              this.axios.get("https://graph.facebook.com/me/accounts?access_token="+localStorage.tokenFacebook)
              .then(function (response) {
                console.log("Token page", response.data.data[0].access_token)
               localStorage.tokenPage = response.data.data[0].access_token
              })
              
              const {data} = await this.axios
              .get(
                "https://graph.facebook.com/me/accounts/seens?fields=feed{id,message,created_time,permalink_url,comments{id},from},fan_count&access_token="+localStorage.tokenFacebook
              )

          const vuex = JSON.parse(localStorage.getItem("vuex"));
          let userid = vuex.user.users[0].Id_Usuario;

          console.log("Respuesta api facebook: ", data);
          console.log("Tamaño del array", data.data[0].feed.data.length);
          let obje = data.data[0].feed.data;

          await obje.forEach(async (item, index) => {
            if (item.comments != null) {
              console.log("Array comentarios", item.comments.data);
              var comentarios = item.comments.data.length;

              this.id_comentario_facebook = item.comments.data[index].id;

              this.axios
                .get(
                  "https://graph.facebook.com/" +
                    item.comments.data[index].id +
                    "?fields=from,message&access_token=" +
                    localStorage.tokenPage
                )
                .then(function(response) {
                  console.log("Id usuario comentario", response.data.from.id);
                  console.log("Id usuario comentario", response.data.from.id);
                  console.log(
                    "Nombre usuario comentario",
                    response.data.from.name
                  );
                  console.log("Mensaje comentario", response.data.message);
                });
            } else {
              var comentarios = 0;
            }

            this.id_post_facebook = data.data[0].feed.data[index].id;

            const response = await this.axios.post("/bextsocial/facebook", {
              id: userid,
              nombre: this.name,
              amigos: data.data[0].fan_count,
              post: data.data[0].feed.data[index].id,
              tipo: null,
              link: data.data[0].feed.data[index].permalink_url,
              mensaje: data.data[0].feed.data[index].message,
              permalink: data.data[0].feed.data[index].permalink_url,
              idioma: null,
              fecha: data.data[0].feed.data[index].created_time,
              comentario: comentarios,
              compartido: null,
              reacciones: null,
              likes: null,
            });
            console.log(response);
          });
          this.$router.push({ name: "Home" });
        } catch (error) {
          console.log(error);
        }
      }
      if (localStorage.loginWith == "twitter") {
        //this.$router.push({ name: 'Home' })
      } else {
        alert("Por favor sincronice alguna cuenta");
      }
    },
    getUserData() {
      this.Carga();
      setTimeout(function(scope){
        scope.Carga();
      
      this.FB.api(
        "/me",
        "GET",
        { fields: "id,name,email" },

        (userInformation) => {
          console.warn("data api", userInformation);
          localStorage.personalID = userInformation.id;
          this.email = userInformation.email;
          this.name = userInformation.name;
        }
      );

      this.FB.getLoginStatus(function(response) {
        if (response.status === "connected") {
          console.log("Respuesta facebook status", response);
          console.log("Token Facebook: " + response.authResponse.accessToken);
          localStorage.tokenFacebook = response.authResponse.accessToken;
          localStorage.loginWith = "facebook";
          this.nextSincronitation = "facebook";
        }
      });

      this.FB.login(
        function(response) {
        FB.api('/me', function(response) {
        console.log('Good to see you, ' + response.name + '.');
        });

          FB.api('/me/accounts', function(response) {

            let id = response.data[0].id
            console.log(id);
            let access_token = response.data[0].access_token
            //console.log(response.data[0].access_token);
            FB.api(id + '?fields=instagram_business_account&access_token='+ access_token, function(response){
              let idInstagram = response.instagram_business_account.id;
              if (idInstagram == null) {
                console.log('no posee una cuenta de instagram asociada a su cuenta de facebook');
              } else {
                console.log('Id de Instagram : '+ idInstagram + '.');
              }

              FB.api(idInstagram + '/media?fields=comments_count,media_url,username,caption,like_count,timestamp,permalink', function(response){
                let objInstagramPost = Object(response.data)
               
                scope.postInstagram(objInstagramPost)
              });

              FB.api(idInstagram + '?fields=id,followers_count,name,username,profile_picture_url,media_count,stories,biography', function(response){
                let objInstagramUser = Object(response)
                scope.postInstagram(objInstagramUser)
                 console.log(objInstagramUser);

                let imgInstagram = response.profile_picture_url;
                console.log(imgInstagram);

              });
            });

          });

        },
        {
          scope:
            "public_profile,email,pages_show_list,read_insights,pages_read_user_content,pages_read_engagement,instagram_basic,instagram_manage_comments,instagram_manage_insights",
        }
      );

      },
      2000,
      this
      );

    },
    postInstagram(objInstagramPost, objInstagramUser){

      //console.log(objInstagramPost, objInstagramUser)

      const axios = require('axios');

      const vuex = JSON.parse(localStorage.getItem("vuex"));
      let userid = vuex.user.users[0].Id_Usuario;

      for (let i = 0; i < objInstagramPost.length; i++) {
        const element = objInstagramPost[i];
        const instagram = axios.post('/bextsocial/instagram',{

          iduser: userid,
          id: objInstagramPost[i].id,
          caption: objInstagramPost[i].caption,
          comments_count: objInstagramPost[i].comments_count,
          like_count: objInstagramPost[i].like_count,
          media_url: objInstagramPost[i].media_url,
          permalink: objInstagramPost[i].permalink,
          timestamp: objInstagramPost[i].timestamp,
          username: objInstagramPost[i].username 

        }).then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
        
      }

    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected;
      this.FB = payload.FB;
      if (this.isConnected) this.getUserData();
    },
    onLogin() {
      this.isConnected = true;
      this.getUserData();
    },
    onLogout() {
      this.isConnected = false;
    },
  },
};
</script>
